import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FaUserAlt } from "react-icons/fa";

export default function Sidebar() {
  const userRole = localStorage.getItem("User_Role");
  const [isOpen, setIsOpen] = useState(false);
  const [isCandidate, setIsCandidate] = useState(false);
  const [isHR, setIsHR] = useState(false);
  const [isInterviwer, setIsInterviwer] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleCandidate = () => {
    setIsCandidate(!isCandidate);
  };
  const toggleHR = () => {
    setIsHR(!isHR);
  };
  const toggleInterviwer = () => {
    setIsInterviwer(!isInterviwer);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* ___________________________________________Admin Sidebar_________________________________________ */}
      {userRole === "Admin" && (
        <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
          <nav
            className={`flex-column sidebar shadow bg-body  h-100 ${
              isOpen ? "open" : ""
            }`}
            style={{
              width: isOpen ? "15rem" : "4rem",
              minWidth: isOpen ? "15rem" : "4rem",
            }}
          >
            <div className="d-md-none">
              <button className="btn btn-dark mt-2" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
              </button>
            </div>
            <ul className="nav nav-pills flex-column mb-auto text-start mt-2">
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/Admindashboard"
                >
                  <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark mt-2"
                  activeClassName="active"
                  to="/crudHome"
                >
                  <i className="fas fa-database me-2"></i>{" "}
                  {isOpen ? " Admin Panel" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link bgside text-dark d-flex align-items-center mt-2"
                  onClick={toggleCandidate}
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    <FaUserAlt className="me-2" />
                    {isOpen ? "Candidate" : ""}
                  </span>
                  <FontAwesomeIcon
                    icon={isCandidate ? faChevronUp : faChevronDown}
                    className="ms-auto"
                  />
                </div>
                {isCandidate && (
                  <ul className="ps-2" style={{ listStyleType: "none" }}>
                    <li className="nav-item ">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/jobapplication"
                      >
                        <i className="fas fa-user me-2"></i>{" "}
                        {isOpen ? "Create Profile" : ""}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/personalityquizform"
                      >
                        <i className="fas fa-brain me-2"></i>{" "}
                        {isOpen ? "Personality Quiz" : ""}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/Jobpostingresult"
                      >
                        <i className="fas fa-briefcase me-2"></i>{" "}
                        {isOpen ? "Job Openings" : ""}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/DocumentUpload"
                      >
                        <i className="fas fa-file-text me-2"></i>{" "}
                        {isOpen ? "Upload Document" : ""}
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <div
                  className="nav-link bgside text-dark d-flex align-items-center mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={toggleHR}
                >
                  <span>
                    <FaUserAlt className="me-2" />
                    {isOpen ? "HR" : ""}
                  </span>
                  <FontAwesomeIcon
                    icon={isHR ? faChevronUp : faChevronDown}
                    className="ms-auto"
                  />
                </div>
                {isHR && (
                  <ul className="ps-2" style={{ listStyleType: "none" }}>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/Jobposting"
                      >
                        <i className="fas fa-plus-square me-2"></i>{" "}
                        {isOpen ? "Job Posting" : ""}
                      </NavLink>
                    </li>

                    <li className="nav-item ">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/jobapplicationresult"
                      >
                        <i className="fas fa-users me-2"></i>{" "}
                        {isOpen ? "Job Applicants" : ""}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/interviewSchedule"
                      >
                        <i className="fas fa-list me-2"></i>{" "}
                        {isOpen ? "Schedule Interview" : ""}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        to="/InterviewerFeedback"
                      >
                        <i className="fas fa-file-text me-2"></i>{" "}
                        {isOpen ? "Interview Evaluation " : ""}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/DocumentViewList"
                      >
                        <i className="fas fa-file-contract me-2"></i>{" "}
                        {isOpen ? "Document View" : ""}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        activeClassName="active"
                        to="/letterform"
                      >
                        <i className="fas fa-file-contract me-2"></i>{" "}
                        {isOpen ? "Offer Letter" : ""}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        to="/InterviewerDetailList"
                      >
                        <i className="fas fa-list me-2"></i>{" "}
                        {isOpen ? "Interviewers List" : ""}
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <div
                  className="nav-link bgside text-dark d-flex align-items-center mt-2"
                  onClick={toggleInterviwer}
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    <FaUserAlt className="me-2" />
                    {isOpen ? "Interviwer" : ""}
                  </span>
                  <FontAwesomeIcon
                    icon={isInterviwer ? faChevronUp : faChevronDown}
                    className="ms-auto"
                  />
                </div>
                {isInterviwer && (
                  <ul className="ps-2" style={{ listStyleType: "none" }}>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        to="/InterviewerForm"
                      >
                        <i className="fas fa-file-text me-2"></i>{" "}
                        {isOpen ? "Interviewer Form" : ""}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link bgside text-dark"
                        to="/evaluationForm"
                      >
                        <i className="fas fa-file-text me-2"></i>{" "}
                        {isOpen ? "Evaluation Form" : ""}
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark mt-2"
                  activeClassName="active"
                  to="/feedback-list"
                >
                  <i className="fas fa-list me-2"></i>{" "}
                  {isOpen ? "Feedback List" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark mt-2"
                  activeClassName="active"
                  to="/pdf-viewer"
                >
                  <i className="fas fa-file-pdf me-2"></i>{" "}
                  {isOpen ? "Scan Pdf" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark mt-2"
                  activeClassName="active"
                  to="/feedback"
                >
                  <i className="fas fa-comments me-2"></i>{" "}
                  {isOpen ? "Feedback" : ""}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/*_______________________________________Candidate Sidebar________________________________________ */}

      {userRole === "Candidate" && (
        <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
          <nav
            className={`flex-column  sidebar shadow bg-body  h-100 ${
              isOpen ? "open" : ""
            }`}
            style={{
              width: isOpen ? "15rem" : "4rem",
              minWidth: isOpen ? "15rem" : "4rem",
            }}
          >
            <div className="d-md-none">
              <button className="btn btn-dark m-2" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
              </button>
            </div>
            <ul className="nav nav-pills flex-column mb-auto text-start mt-2 ">
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/dashboard"
                >
                  <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/jobapplication"
                >
                  <i className="fas fa-user me-2"></i>{" "}
                  {isOpen ? "Create Profile" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/personalityquizform"
                >
                  <i className="fas fa-brain me-2"></i>{" "}
                  {isOpen ? "Personality Quiz" : ""}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/Jobpostingresult"
                >
                  <i className="fas fa-briefcase me-2"></i>{" "}
                  {isOpen ? "Job Openings" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/DocumentUpload"
                >
                  <i className="fas fa-file-text me-2"></i>{" "}
                  {isOpen ? "Upload Document" : ""}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/pdf-viewer"
                >
                  <i className="fas fa-file-pdf me-2"></i>{" "}
                  {isOpen ? "Scan Pdf" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/feedback"
                >
                  <i className="fas fa-comments me-2"></i>{" "}
                  {isOpen ? "Feedback" : ""}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/*_________________________________________HRSidebar________________________________________*/}

      {userRole === "HR" && (
        <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
          <nav
            className={`flex-column  sidebar shadow bg-body  h-100 ${
              isOpen ? "open" : ""
            }`}
            style={{
              width: isOpen ? "15rem" : "4rem",
              minWidth: isOpen ? "15rem" : "4rem",
            }}
          >
            <div className="d-md-none">
              <button className="btn btn-light mt-2" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
              </button>
            </div>
            <ul className="nav nav-pills flex-column mb-auto text-start ">
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark mt-2"
                  activeClassName="active"
                  to="/HRDashboard"
                >
                  <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/Jobposting"
                >
                  <i className="fas fa-plus-square me-2"></i>{" "}
                  {isOpen ? "Job Posting" : ""}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/jobapplicationresult"
                >
                  <i className="fas fa-users me-2"></i>{" "}
                  {isOpen ? "Job Applicants" : ""}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/interviewSchedule"
                >
                  <i className="fas fa-list me-2"></i>{" "}
                  {isOpen ? "Schedule Interview" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  to="/InterviewerFeedback"
                >
                  <i className="fas fa-file-text me-2"></i>{" "}
                  {isOpen ? "Interview Evaluation " : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/DocumentViewList"
                >
                  <i className="fas fa-file-contract me-2"></i>{" "}
                  {isOpen ? "Document View" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/letterform"
                >
                  <i className="fas fa-file-contract me-2"></i>{" "}
                  {isOpen ? "Offer Letter" : ""}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/pdf-viewer"
                >
                  <i className="fas fa-file-pdf me-2"></i>{" "}
                  {isOpen ? "Scan Pdf" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/feedback"
                >
                  <i className="fas fa-comments me-2"></i>{" "}
                  {isOpen ? "Feedback" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/feedback-list"
                >
                  <i className="fas fa-list me-2"></i>{" "}
                  {isOpen ? "Feedback List" : ""}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/* _____________________________________Interviewer Sidebar____________________________ */}

      {userRole === "Interviewer" && (
        <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
          <nav
            className={`flex-column bg-light sidebar shadow bg-body  h-100 ${
              isOpen ? "open" : ""
            }`}
            style={{
              width: isOpen ? "15rem" : "4rem",
              minWidth: isOpen ? "15rem" : "4rem",
            }}
          >
            <div className="d-md-none">
              <button className="btn btn-dark mt-2" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
              </button>
            </div>
            <ul className="nav nav-pills flex-column mb-auto text-start mt-2">
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/InterviewerDashboard"
                >
                  <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  to="/InterviewerForm"
                >
                  <i className="fas fa-file-text me-2"></i>{" "}
                  {isOpen ? "Interviewer Form" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  to="/evaluationForm"
                >
                  <i className="fas fa-file-text me-2"></i>{" "}
                  {isOpen ? "Evaluation Form" : ""}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link bgside text-dark"
                  activeClassName="active"
                  to="/feedback"
                >
                  <i className="fas fa-comments me-2"></i>{" "}
                  {isOpen ? "Feedback" : ""}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}

// _____________________________original_________________________________
// import { NavLink } from "react-router-dom";
// import { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

// export default function Sidebar() {
//   const userRole = localStorage.getItem("User_Role");
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       setIsOpen(window.innerWidth >= 768);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (
//     <>
//       {/* ___________________________________________Admin Sidebar_________________________________________ */}
//       {userRole === "Admin" && (
//         <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
//           <nav
//             className={`flex-column sidebar shadow bg-body  h-100 ${
//               isOpen ? "open" : ""
//             }`}
//             style={{
//               width: isOpen ? "15rem" : "4rem",
//               minWidth: isOpen ? "15rem" : "4rem",
//             }}
//           >
//             <div className="d-md-none">
//               <button className="btn btn-dark mt-2" onClick={toggleSidebar}>
//                 <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
//               </button>
//             </div>
//             <ul className="nav nav-pills flex-column mb-auto text-start mt-2">
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/Admindashboard"
//                 >
//                   <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/crudHome"
//                 >
//                   <i className="fas fa-database me-2"></i>{" "}
//                   {isOpen ? " Admin Panel" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/jobapplication"
//                 >
//                   <i className="fas fa-user me-2"></i>{" "}
//                   {isOpen ? "Create Profile" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/personalityquizform"
//                 >
//                   <i className="fas fa-brain me-2"></i>{" "}
//                   {isOpen ? "Personality Quiz" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/Jobpostingresult"
//                 >
//                   <i className="fas fa-briefcase me-2"></i>{" "}
//                   {isOpen ? "Job Openings" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/DocumentUpload"
//                 >
//                   <i className="fas fa-file-text me-2"></i>{" "}
//                   {isOpen ? "Upload Document" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/Jobposting"
//                 >
//                   <i className="fas fa-plus-square me-2"></i>{" "}
//                   {isOpen ? "Job Posting" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/jobapplicationresult"
//                 >
//                   <i className="fas fa-users me-2"></i>{" "}
//                   {isOpen ? "Job Applicants" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/interviewSchedule"
//                 >
//                   <i className="fas fa-list me-2"></i>{" "}
//                   {isOpen ? "Schedule Interview" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/DocumentViewList"
//                 >
//                   <i className="fas fa-file-contract me-2"></i>{" "}
//                   {isOpen ? "Document View" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink className="nav-link bgside text-dark" to="/letterform">
//                   <i className="fas fa-file-contract me-2"></i>{" "}
//                   {isOpen ? "Offer Letter" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   to="/InterviewerDetailList"
//                 >
//                   <i className="fas fa-list me-2"></i>{" "}
//                   {isOpen ? "Interviewers List" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   to="/InterviewerForm"
//                 >
//                   <i className="fas fa-file-text me-2"></i>{" "}
//                   {isOpen ? "Interviewer Form" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   to="/evaluationForm"
//                 >
//                   <i className="fas fa-file-text me-2"></i>{" "}
//                   {isOpen ? "Evaluation Form" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   to="/InterviewerFeedback"
//                 >
//                   <i className="fas fa-list me-2"></i>{" "}
//                   {isOpen ? "Interview Evaluation " : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/feedback-list"
//                 >
//                   <i className="fas fa-list me-2"></i>{" "}
//                   {isOpen ? "Feedback List" : ""}
//                 </NavLink>
//               </li>
//               {/* <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/pdf-viewer"
//                 >
//                   <i className="fas fa-file-pdf me-2"></i>{" "}
//                   {isOpen ? "Scan Pdf" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/feedback"
//                 >
//                   <i className="fas fa-comments me-2"></i>{" "}
//                   {isOpen ? "Feedback" : ""}
//                 </NavLink>
//               </li> */}
//             </ul>
//           </nav>
//         </div>
//       )}

//       {/*_______________________________________Candidate Sidebar________________________________________ */}

//       {userRole === "Candidate" && (
//         <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
//           <nav
//             className={`flex-column  sidebar shadow bg-body  h-100 ${
//               isOpen ? "open" : ""
//             }`}
//             style={{
//               width: isOpen ? "15rem" : "4rem",
//               minWidth: isOpen ? "15rem" : "4rem",
//             }}
//           >
//             <div className="d-md-none">
//               <button className="btn btn-dark m-2" onClick={toggleSidebar}>
//                 <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
//               </button>
//             </div>
//             <ul className="nav nav-pills flex-column mb-auto text-start mt-2 ">
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/dashboard"
//                 >
//                   <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/jobapplication"
//                 >
//                   <i className="fas fa-user me-2"></i>{" "}
//                   {isOpen ? "Create Profile" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/personalityquizform"
//                 >
//                   <i className="fas fa-brain me-2"></i>{" "}
//                   {isOpen ? "Personality Quiz" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/Jobpostingresult"
//                 >
//                   <i className="fas fa-briefcase me-2"></i>{" "}
//                   {isOpen ? "Job Openings" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/DocumentUpload"
//                 >
//                   <i className="fas fa-file-text me-2"></i>{" "}
//                   {isOpen ? "Upload Document" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/pdf-viewer"
//                 >
//                   <i className="fas fa-file-pdf me-2"></i>{" "}
//                   {isOpen ? "Scan Pdf" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/feedback"
//                 >
//                   <i className="fas fa-comments me-2"></i>{" "}
//                   {isOpen ? "Feedback" : ""}
//                 </NavLink>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       )}

//       {/*_________________________________________HRSidebar________________________________________*/}

//       {userRole === "HR" && (
//         <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
//           <nav
//             className={`flex-column  sidebar shadow bg-body  h-100 ${
//               isOpen ? "open" : ""
//             }`}
//             style={{
//               width: isOpen ? "15rem" : "4rem",
//               minWidth: isOpen ? "15rem" : "4rem",
//             }}
//           >
//             <div className="d-md-none">
//               <button className="btn btn-light mt-2" onClick={toggleSidebar}>
//                 <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
//               </button>
//             </div>
//             <ul className="nav nav-pills flex-column mb-auto text-start ">
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark mt-2"
//                   activeClassName="active"
//                   to="/HRDashboard"
//                 >
//                   <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/Jobposting"
//                 >
//                   <i className="fas fa-plus-square me-2"></i>{" "}
//                   {isOpen ? "Job Posting" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/jobapplicationresult"
//                 >
//                   <i className="fas fa-users me-2"></i>{" "}
//                   {isOpen ? "Job Applicants" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/interviewSchedule"
//                 >
//                   <i className="fas fa-list me-2"></i>{" "}
//                   {isOpen ? "Schedule Interview" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   to="/InterviewerFeedback"
//                 >
//                   <i className="fas fa-file-text me-2"></i>{" "}
//                   {isOpen ? "Interview Evaluation " : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/DocumentViewList"
//                 >
//                   <i className="fas fa-file-contract me-2"></i>{" "}
//                   {isOpen ? "Document View" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/letterform"
//                 >
//                   <i className="fas fa-file-contract me-2"></i>{" "}
//                   {isOpen ? "Offer Letter" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/pdf-viewer"
//                 >
//                   <i className="fas fa-file-pdf me-2"></i>{" "}
//                   {isOpen ? "Scan Pdf" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/feedback"
//                 >
//                   <i className="fas fa-comments me-2"></i>{" "}
//                   {isOpen ? "Feedback" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/feedback-list"
//                 >
//                   <i className="fas fa-list me-2"></i>{" "}
//                   {isOpen ? "Feedback List" : ""}
//                 </NavLink>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       )}

//       {/* _____________________________________Interviewer Sidebar____________________________ */}

//       {userRole === "Interviewer" && (
//         <div className={`d-flex flex-column ${isOpen ? "sidebar-open" : ""}`}>
//           <nav
//             className={`flex-column bg-light sidebar shadow bg-body  h-100 ${
//               isOpen ? "open" : ""
//             }`}
//             style={{
//               width: isOpen ? "15rem" : "4rem",
//               minWidth: isOpen ? "15rem" : "4rem",
//             }}
//           >
//             <div className="d-md-none">
//               <button className="btn btn-dark mt-2" onClick={toggleSidebar}>
//                 <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
//               </button>
//             </div>
//             <ul className="nav nav-pills flex-column mb-auto text-start mt-2">
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/InterviewerDashboard"
//                 >
//                   <i className="fas fa-home me-2"></i> {isOpen ? "Home" : ""}
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   to="/InterviewerForm"
//                 >
//                   <i className="fas fa-file-text me-2"></i>{" "}
//                   {isOpen ? "Interviewer Form" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   to="/evaluationForm"
//                 >
//                   <i className="fas fa-file-text me-2"></i>{" "}
//                   {isOpen ? "Evaluation Form" : ""}
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link bgside text-dark"
//                   activeClassName="active"
//                   to="/feedback"
//                 >
//                   <i className="fas fa-comments me-2"></i>{" "}
//                   {isOpen ? "Feedback" : ""}
//                 </NavLink>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       )}
//     </>
//   );
// }
