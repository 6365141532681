import React, { useState, useEffect, useRef } from "react";
import DashNavbar from "./DashNavbar";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import "../css/dash.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FcApproval, FcCancel } from "react-icons/fc";
import { FaEye } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import axios from "axios";

function DocumentUpload() {
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState({
    aadhar: "",
    pan: "",
    hsc: "",
    ssc: "",
    degree: "",
    other: "",
  });
  const [selectedFileNames, setSelectedFileNames] = useState({
    aadhar: "",
    pan: "",
    hsc: "",
    ssc: "",
    degree: "",
    other: "",
  });
  const [filePaths, setFilePaths] = useState({
    aadhar: "",
    pan: "",
    hsc: "",
    ssc: "",
    degree: "",
    other: "",
  });
  const [statuses, setStatuses] = useState({
    aadhar: null,
    pan: null,
    hsc: null,
    ssc: null,
    degree: null,
    other: null,
  });
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const aadharInputRef = useRef(null);
  const panInputRef = useRef(null);
  const hscInputRef = useRef(null);
  const sscInputRef = useRef(null);
  const degreeInputRef = useRef(null);
  const otherInputRef = useRef(null);

  useEffect(() => {
    const storedUsername = localStorage.getItem("UserName");
    const storedEmail = localStorage.getItem("email");
    setUsername(storedUsername || "");
    setEmail(storedEmail || "");
    fetchDocuments(storedEmail);
  }, []);

  const fetchDocuments = async (email) => {
    try {
      const response = await axios.get(
        `https://tmaasbackend.tdtlworld.com/api/document_user_status?email=${email}`
      );
      const document = response.data[0];
      setFileNames({
        aadhar: document.aadhar?.split("/").pop() || "",
        pan: document.pan?.split("/").pop() || "",
        hsc: document.hsc?.split("/").pop() || "",
        ssc: document.ssc?.split("/").pop() || "",
        degree: document.degree?.split("/").pop() || "",
        other: document.other?.split("/").pop() || "",
      });
      setFilePaths({
        aadhar: document.aadhar || "",
        pan: document.pan || "",
        hsc: document.hsc || "",
        ssc: document.ssc || "",
        degree: document.degree || "",
        other: document.other || "",
      });
      setStatuses({
        aadhar: document.aadhar_status,
        pan: document.pan_status,
        hsc: document.hsc_status,
        ssc: document.ssc_status,
        degree: document.degree_status,
        other: document.other_status,
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleChange = (e) => {
    const { name, files: newFiles } = e.target;
    const fileName = newFiles[0]?.name || "";

    setFiles((prevFiles) => [...prevFiles, { name, file: newFiles[0] }]);
    setFileNames((prevNames) => ({ ...prevNames, [name]: fileName }));
    setSelectedFileNames((prevSelectedFileNames) => ({
      ...prevSelectedFileNames,
      [name]: fileName,
    }));
  };

  const viewDocument = async (email, documentType) => {
    try {
      const response = await fetch(
        "https://tmaasbackend.tdtlworld.com/api/view_document/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, document_type: documentType }),
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } else {
        const error = await response.json();
        console.error("Error viewing document:", error.error);
      }
    } catch (error) {
      console.error("Error viewing document:", error);
    }
  };

  const handleDocumentClick = (email, documentType) => {
    viewDocument(email, documentType);
  };

  const handleButtonClick = (inputRef) => {
    inputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    files.forEach((file) => {
      formData.append(file.name, file.file);
    });

    try {
      const response = await fetch(
        "https://tmaasbackend.tdtlworld.com/api/document_upload/",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Upload Successful",
          text: "Your documents have been uploaded successfully!",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        fetchDocuments(email); // Refresh document statuses
      } else {
        Swal.fire({
          icon: "error",
          title: "Upload Failed",
          text: "Oops! Something went wrong. Please try again later.",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while uploading. Please try again later.",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const iconStyle = { fontSize: "1.5em" };

  return (
    <>
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="col-lg-10 col-md-8 col-sm-12 main dash4">
          <div className="container mt-4">
            <div className="text-start">
              <h3>Upload/Edit Documents</h3>
              <p>Fill in the details below to upload/edit documents:</p>
            </div>
            <div className="auth-inner mt-5 w-100">
              <form onSubmit={handleSubmit} className="upload-form">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Document Name</th>
                        <th>Choose File</th>
                        <th>Selected File</th>
                        <th>Verification Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          label: "Aadhar Card",
                          name: "aadhar",
                          ref: aadharInputRef,
                        },
                        { label: "PAN Card", name: "pan", ref: panInputRef },
                        {
                          label: "HSC Mark Sheet",
                          name: "hsc",
                          ref: hscInputRef,
                        },
                        {
                          label: "SSC Mark Sheet",
                          name: "ssc",
                          ref: sscInputRef,
                        },
                        {
                          label: "Degree/Mark Sheet",
                          name: "degree",
                          ref: degreeInputRef,
                        },
                        {
                          label: "Other Document (In 1 PDF)",
                          name: "other",
                          ref: otherInputRef,
                        },
                      ].map((doc) => (
                        <tr key={doc.name}>
                          <td className="text-start">{doc.label}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => handleButtonClick(doc.ref)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.5 3H12H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H11M13.5 3L19 8.625M13.5 3V7.625C13.5 8.17728 13.9477 8.625 14.5 8.625H19M19 8.625V11.8125"
                                  stroke="#fffffff"
                                  stroke-width="2"
                                ></path>
                                <path
                                  d="M17 15V18M17 21V18M17 18H14M17 18H20"
                                  stroke="#fffffff"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                              Upload File
                            </button>

                            <input
                              type="file"
                              className="form-control"
                              id={doc.name}
                              name={doc.name}
                              ref={doc.ref}
                              required
                              onChange={handleChange}
                              style={{ display: "none" }}
                              accept=".pdf"
                            />
                          </td>
                          <td>
                            {filePaths[doc.name] ? (
                              <div className="d-flex justify-content-center">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDocumentClick(email, doc.name);
                                  }}
                                >
                                  {fileNames[doc.name]}
                                </a>
                                <FaEye
                                  className="ms-2"
                                  onClick={() =>
                                    handleDocumentClick(email, doc.name)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            ) : selectedFileNames[doc.name] ? (
                              <span>{selectedFileNames[doc.name]}</span>
                            ) : (
                              <small className="text-muted">
                                No file selected
                              </small>
                            )}
                          </td>
                          <td>
                            {statuses[doc.name] === "Y" && (
                              <FcApproval style={iconStyle} />
                            )}
                            {statuses[doc.name] === "N" && (
                              <FcCancel style={iconStyle} />
                            )}
                            {statuses[doc.name] === null && (
                              <IoWarningOutline style={iconStyle} />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <footer className="footer py-3 bg-light mt-2">
                  <button type="submit" className="btn btn-primary">
                    Submit Document
                  </button>
                </footer>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentUpload;
